<template>
    <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="PENDING_APPROVAL" tab="待审批">
            <table-page :config="config" />
        </a-tab-pane>
        <a-tab-pane key="REJECTED" tab="已拒绝">
            <table-page :config="configRejected" />
        </a-tab-pane>
        <a-tab-pane key="PASSED" tab="已通过">
            <table-page :config="configPassed" />
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
export default {
    name: 'SaasAdminRebateRulesReviewDetail',
    components: { TablePage },
    props: ['selectedRows'],
    data() {
        return {
            activeKey: 'PENDING_APPROVAL',
            config: {
                checkbox: true,
                dataUrl: `/institutions/audit/list?auditStatusIs=PENDING_APPROVAL`,
                filter: {
                    controls: []
                },
                columns: [
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '150px',
                    },
                    {
                        key: 'paymentType',
                        title: '类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                RECEIPT: {
                                    text: '收款',
                                    color: 'orange'
                                },
                                REFUND: {
                                    text: '退款',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'paymentTime',
                        title: '收支时间',
                        width: '130px',
                        type: 'datetime'
                    },

                    {
                        key: 'amount',
                        title: '金额',
                        width: '150px',
                        type: 'numeric',
                        config: {
                            format: 'currency',
                        }
                    },
                    {
                        key: 'paymentVoucher',
                        title: '支付凭证',
                        width: '100px',
                        type: 'image'
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        fixed: 'right',
                        width: document.documentElement.clientWidth <= 768 ? '70px' : '150px'
                    }
                ],
                /* 表格操作 */
                actions: [
                    {
                        key: 'edit',
                        text: '通过',
                        icon: 'plus',
                        type: 'dialog',
                        isSelected: true,  // 是否必勾选
                        determine: true,  // 是否需要判定条
                        permission: '/institutions/audit/pass',
                        needPermission: true,
                        showPermission: (e) => {
                            if (e !== 1) return true
                        },
                        modalObj: (rowData, tableData) => {
                            return `您共选择 ${rowData.length} 条收支记录，是否全部通过? `
                        },
                        config: {
                            title: '审批通过',
                            submitUrl: '/institutions/audit/pass',
                            submitText: '确认',
                            controls: [
                                {
                                    key: 'reviewResponse',
                                    label: '审批回复',
                                    type: 'textarea',
                                    config: {
                                        rules: [
                                            { min: 1, max: 50, message: '只能输入1-50个字符' }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                    {
                        key: 'edit',
                        text: '拒绝',
                        icon: 'plus',
                        type: 'dialog',
                        isSelected: true,  // 是否必勾选
                        determine: true,  // 是否需要判定条
                        permission: '/institutions/audit/refuse',
                        modalObj: (rowData, tableData) => {
                            return `您共选择 ${rowData.length} 条收支记录，是否全部拒绝?`
                        },
                        config: {
                            title: '审批拒绝',
                            submitUrl: '/institutions/audit/refuse',
                            submitText: '确认',
                            controls: [
                                {
                                    key: 'reviewResponse',
                                    label: '审批回复',
                                    type: 'textarea',
                                    required: true,
                                    config: {
                                        rules: [
                                            { min: 1, max: 50, message: '只能输入1-50个字符' }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ],
                rowActions: [
                    {
                        key: 'detail',
                        text: '订单详情',
                        type: 'blank',
                        permission: '/institutions/audit/details',
                        config: {
                            title: '订单详情',
                            component: () => import("./orderDetail")
                        }
                    },
                    {
                        key: 'detail',
                        text: '审批通过',
                        type: 'blank',
                        permission: '/institutions/audit/pass',
                        needPermission: true,
                        showPermission: (e) => {
                            if (e == 1) return true
                        },
                        config: {
                            title: '审批通过',
                            component: () => import("./adopt.vue")
                        }
                    },
                ],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: 'createdDate'
                },
            },
            configRejected: {
                dataUrl: `/institutions/audit/list?auditStatusIs=REJECTED`,
                filter: {
                    controls: []
                },
                columns: [
                    {
                        key: 'reviewResponse',
                        title: '审批回复',
                        width: '100px',
                        ellipsis: true
                    },
                    {
                        key: 'reviewer.username',
                        title: '审批人',
                        width: '100px',
                    },
                    {
                        key: 'reviewTime',
                        title: '审批时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '150px',
                    },
                    {
                        key: 'paymentType',
                        title: '类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                RECEIPT: {
                                    text: '收款',
                                    color: 'orange'
                                },
                                REFUND: {
                                    text: '退款',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'createdDate',
                        title: '收支时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'amount',
                        title: '金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency',
                        }
                    },
                    {
                        key: 'paymentVoucher',
                        title: '支付凭证',
                        width: '100px',
                        type: 'image'
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        fixed: 'right',
                        width: document.documentElement.clientWidth <= 768 ? '70px' : '150px'
                    }
                ],
                /* 表格操作 */
                actions: [],
                rowActions: [
                    {
                        key: 'detail',
                        text: '订单详情',
                        type: 'blank',
                        permission: '/institutions/audit/details',
                        config: {
                            title: '订单详情',
                            component: () => import("./orderDetail")
                        }
                    },
                ],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: '-createdDate'
                },
            },
            configPassed: {
                dataUrl: `/institutions/audit/list?auditStatusIs=PASSED`,
                filter: {
                    controls: [
                        {
                            key: 'paymentTime',
                            label: '收支时间',
                            type: 'dateRange'
                        },
                    ]
                },
                columns: [
                    {
                        key: 'reviewResponse',
                        title: '审批回复',
                        width: '100px',
                        ellipsis: true
                    },
                    {
                        key: 'reviewer.username',
                        title: '审批人',
                        width: '100px',
                    },
                    {
                        key: 'reviewTime',
                        title: '审批时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '150px',
                    },
                    {
                        key: 'paymentType',
                        title: '类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                RECEIPT: {
                                    text: '收款',
                                    color: 'orange'
                                },
                                REFUND: {
                                    text: '退款',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'createdDate',
                        title: '收支时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'amount',
                        title: '金额',
                        width: '130px',
                        type: 'numeric',
                        config: {
                            format: 'currency',
                        }
                    },
                    {
                        key: 'paymentVoucher',
                        title: '支付凭证',
                        width: '100px',
                        type: 'image'
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        fixed: 'right',
                        width: document.documentElement.clientWidth <= 768 ? '70px' : '150px'
                    }
                ],
                /* 表格操作 */
                actions: [],
                rowActions: [
                    {
                        key: 'detail',
                        text: '订单详情',
                        type: 'blank',
                        permission: '/institutions/audit/details',
                        config: {
                            title: '订单详情',
                            component: () => import("./orderDetail")
                        }
                    },
                    {
                        key: 'detail',
                        text: '提成编辑',
                        type: 'blank',
                        needPermission: true,
                        showPermission: (e) => {
                            if (e == 1) return true
                        },
                        permission: '/institutions/audit/editCommission',
                        config: {
                            title: '提成编辑',
                            component: () => import("./adoptEdit.vue")
                        }
                    }
                ],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: '-createdDate'
                },
            }
        };
    }

};
</script>

<style lang="less" scoped></style>